import React from 'react';
import AppHeading from '../../components/heading/app.rest.heading';
import './app.aboutus.scss';

function AppAboutus(props) {
    return (
      <React.Fragment>
        <AppHeading heading="ABOUT US"/>
        <section id="res-aboutus">
          <article>
          <div className="row col3">
              <div>
                <div className="circle-img" style={{backgroundImage:`url(company_profile.jpeg)`}}></div>
              </div>
            <div className='content'>
              <br/>
                {props.AboutUs && props.AboutUs.map((AboutUs,index) => (
                    <p key={index}>​{AboutUs.desc}</p>
                    // <p dangerouslySetInnerHTML={ {__html: AboutUs.desc} }></p>
                ))}
              </div>
            </div>
          </article>
        </section>
      </React.Fragment>
    )
}

export default AppAboutus;
