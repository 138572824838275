import React,{useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { getAllUser, removeUser } from '../login-modal/api';
import LoginModal from '../login-modal/LoginModal';
import AllUserDataModal from '../login-modal/AllUserDataModal';
import Dropdown from '../profile-dropdown/Dropdown';
import CryptoJS from 'crypto-js';
import './app.header.scss'
import ConfirmModal from '../login-modal/ConfirmModal';
const AppHeader = React.forwardRef((props, ref) => {
    const [panelToogle, setPanel] = useState(false);
    const [isLogin, setIsLogin] = useState(true);

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isUserListModalOpen, setIsUserListModalOpen] = useState(false);
    const [userID, setUserID] = useState('');
    const [userDetail, setUserDetail] = useState('');
    const [allUserData, setAllUserData] = useState([]);
    const secretKey = 'your-secret-key';

    const toggleLoginModal = () => {
        setIsLoginModalOpen(!isLoginModalOpen);
    };

    const decryptMessage = (encryptedMessage) => {
        const bytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    useEffect(() => {
        fetchData()
        const accessToken = localStorage.getItem("accessToken")
        const profileDataFromLocal = localStorage.getItem("profileData")
        if (accessToken) {
            const data = decryptMessage(profileDataFromLocal)
            const profile = JSON.parse(data)
            setUserDetail(profile.fullName)
        }
    }, [])


    const fetchData = async (data) => {
        let accessToken = ''
        if (data?.accessToken) {
            accessToken = data.accessToken
        } else {
            accessToken = localStorage.getItem("accessToken")
        }
        try {
            const response = await getAllUser(accessToken);
            setAllUserData(response)
            if (response.result=="Invalid Token") {
                localStorage.removeItem("accessToken")
            }
            
        } catch (err) {
            console.error(err);
            localStorage.removeItem("accessToken")
            setUserDetail('')
        }
    };
    const handleRemoveClick = async (id) => {
        setIsConfirmModalOpen(true)
        setUserID(id)
    };
    const removeFunction = async (id) => {
        if (isConfirmModalOpen) {
            let accessToken = localStorage.getItem("accessToken")
            try {
                const response = await removeUser(id, accessToken);
                fetchData()
                setIsConfirmModalOpen(false)
                
            } catch (err) {
                console.error(err);
            }
        }
    };

    function toggleButton () {
        if(!panelToogle) setPanel(true);
        else setPanel(false);
    }
    function toggleButtonOff () {
        setPanel(false)
    }
    
    function handleClick(data) {
        fetchData(data)
    };
    function handleLogoutUpdate() {
        fetchData()
        setIsLogin(true)
    };
    
    function handleAddMember() {
        setIsLogin(false)
        setIsLoginModalOpen(true)
    };
    function handleConfirmClick(value) {
        setIsConfirmModalOpen(value)
        if (value) {
            removeFunction(userID)
        }
    };


    // const { location } = props;
    return (
        <>
            <header id="app-header" ref={ref}>
                <nav className="navbar navbar-default">
                    <div className={"container " + (panelToogle ? 'openNav' : '')}>
                        <div className="navbar-brand" href="//www.airtel.in" >
                            <span className="logo">
                                <img src="vvrss_logo.png"/>
                            </span>
                        </div>
                        <a className="mobile-nav icon-menu" onClick={toggleButton}></a>
                        <ul id="main-menu" className="main-menu" onClick={toggleButtonOff}>
                            <li><NavLink exact to="/">Home</NavLink></li>
                            <li><NavLink to="/about">About us</NavLink></li>
                            <li><NavLink to="/services">Our Events</NavLink></li>
                            <li><NavLink to="/blogs">Blogs</NavLink></li>
                            <li><NavLink to="/contact">Contact us</NavLink></li>
                        </ul>
                        <ul className="right-menu">
                            <li className="phone"><span className="icon-phone">+91 967540809</span></li>
                            <li className="profile_dropdown">
                                {userDetail ? <Dropdown openMemberClick={()=>setIsUserListModalOpen(true)} userDetail={userDetail} handleLogoutUpdate={handleLogoutUpdate} handleAddMember={handleAddMember}/>:<button onClick={toggleLoginModal}>Login</button>}
                            </li>
                        </ul>
                        
                    </div>
                </nav>
            </header>
            <LoginModal isOpen={isLoginModalOpen} onClose={toggleLoginModal} onChildClick={handleClick} isLogin={isLogin}/>
            <AllUserDataModal isOpen={isUserListModalOpen} closeHandle={()=>setIsUserListModalOpen(false)} allUserData={allUserData} handleRemoveClick={handleRemoveClick}/>
            <ConfirmModal data="Are you sure wan to remove user ?" isOpen={isConfirmModalOpen} handleConfirmClick={handleConfirmClick}/>
        </>
        
    )
})

export default AppHeader;

