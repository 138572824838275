import React from 'react';
import './app.footer.scss'

function AppFooter() {
    
    return (
        <footer class="footer">
            <div class="container footer-row">
                <div class="footer-col">
                <h4>Profile</h4>
                    <div>
                        <p>विश्वकर्मा भगवान की वंशावली, चरित्र दर्पण, प्रकटोत्सव, पुजा, कथा, चालीसा, स्तुति, प्रार्थना, आरती, मंदिर, शतकम स्वरुप, सुक्त इत्यादि जानकारियों के लिए</p>
                    </div>
                        <div className="social">
                        <a className="icon-facebook" target="_blank" href="https://www.facebook.com/vvrss/" data="facebook"></a>
                        <a className="icon-youtube" target="_blank" data="youtube"></a>
                        <a className="icon-instagram" target="_blank" href="https://www.instagram.com/vvrss/" data="instagram"></a>
                        <a className="icon-twitter" target="_blank" href="https://twitter.com/vvrss" data="twitter"></a>
                    </div>
                </div>
                <div class="footer-col">
                <h4>Explore</h4>
                <ul class="links">
                    <li><a href="/about">About us</a></li>
                    <li><a href="#">Services</a></li>
                    <li><a href="#">Blogs</a></li>  
                    <li><a href="#">Contact</a></li>
                </ul>
                </div>
                <div class="footer-col">
                <h4>Legal</h4>
                <ul class="links">
                    <li><a href="#">Customer Agreement</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Security</a></li>
                    <li><a href="#">Testimonials</a></li>
                </ul>
                </div>
                <div class="footer-col">
                <h4>Newsletter</h4>
                <p>
                    Subscribe to our newsletter for a weekly dose
                    of news, updates, helpful tips, and
                    exclusive offers.
                </p>
                <form>
                    <input type="text" placeholder="Your email" required/>
                    <button type="submit">SUBSCRIBE</button>
                </form>
               
                </div>
            </div>
                {/* <div className="row copyright">
                    <div className="container">
                        <span>Copyright © 2023 vvrss. All Right Reserved.</span>
                    </div>
                </div> */}
        </footer>
    )
}

export default AppFooter;
