import React, { useState, useRef, useEffect } from 'react';
import './Dropdown.scss';

const Dropdown = ({userDetail, handleLogoutUpdate, handleAddMember, openMemberClick}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const addMemberClick = () => {
        setIsOpen(!isOpen);
        handleAddMember()
    };

    const handleLogout = () => {
        setIsOpen(false);
        localStorage.removeItem("accessToken")
        handleLogoutUpdate()
        window.location.reload(); //TO_DO
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <div className="dropdown-icon">
                <a onClick={toggleDropdown}> <img className="avtar_icon" src="avatar.svg" width="30" style={{ maxHeight: "30px"}} /> <img src="down-arrow.svg" width="13"/></a>
            </div>
            {isOpen && (
                <div className="dropdown-content">
                    <div className="name"><img className="avtar_icon" src="avatar.svg" width="30" style={{ maxHeight: "25px"}} /> {userDetail}</div>
                    <a onClick={() => addMemberClick()}>
                            Add Member
                            <img src="plus.svg" width="20"/>
                    </a>
                    <a onClick={() => openMemberClick()}>
                        Member List
                        <img src="user-list.svg" width="20"/>
                    </a>
                    <a className="logout" onClick={() => handleLogout()}>
                        Sign Out
                        <img src="logout.svg" width="20"/>
                    </a>
                </div>
            )}
        </div>
    );
};

export default Dropdown;