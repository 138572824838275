import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { login, register } from './api';
import './LoginModal.css';

const LoginModal = ({ isOpen, onClose, onChildClick, isLogin }) => {
    const [error, setError] = useState('');
    const [errors, setErrors] = useState('');
    const [profileData, serProfileData] = useState({});
    const secretKey = 'your-secret-key';

    const [formData, setFormData] = useState({
        fullName: '',
        mobileNumber: '',
        password: '',
        confirmPassword:''
    });

    console.log(profileData,'profileData')

    const encryptMessage = (message) => {
        return CryptoJS.AES.encrypt(message, secretKey).toString();
    };

    const handleChangeMobile = (event) => {
        const {name, value } = event.target;
        // Only allow numeric input and restrict length to 10 digits
        if (/^\d*$/.test(value) && value.length <= 10) {
            setFormData({ ...formData, [name]: value })
            setError('');

            // Regex for validating mobile numbers
            const mobileRegex = /^\d{10}$/;

            // Validate input
            if (!mobileRegex.test(value) && value !== '') {
                //  (prevState => ({
                //     ...prevState,        // Spread the previous state
                //     mobileNumber: "Please enter a valid mobile number (10 digits)."        // Update the name property
                // }));
            }
        } else {
            setErrors(prevState => ({
                ...prevState,        // Spread the previous state
                mobileNumber: "Only numeric values allowed and up to 10 digits."        // Update the name property
            }));
        }
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.mobileNumber) {
            newErrors.mobileNumber = 'mobileNumber is required';
        }
        if (!formData.fullName) {
            newErrors.fullName = 'Name is required';
        }
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
        }
        return newErrors;
    };

    const handleChange = (event) => {
        const {name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (!isLogin && Object.keys(validationErrors).length != 0) {
            setErrors(validationErrors)
            return
        } 
        setErrors('');
        setError('');
        try {
            if (isLogin) {
                const data = await login(formData);
                console.log('Login Success:', data);
                onChildClick(data)
                window.location.reload(); //TO-DO
                localStorage.setItem("accessToken",data.accessToken)
                localStorage.setItem("profileData",encryptMessage(JSON.stringify(data.user)))
            } else {
                if (formData.password !== formData.confirmPassword) {
                    // setErrors("Passwords don't match");
                    setErrors(prevState => ({
                        ...prevState,        // Spread the previous state
                        password: "Passwords don't match"        // Update the name property
                      }));
                    return;
                }
                const data = await register(formData);
                console.log('Registration Success:', data);

            }
            onClose(); // Close modal after submission
        } catch (err) {
            setErrors(prevState => ({
                ...prevState,        // Spread the previous state
                password: err.response?.data?.message        // Update the name property
              }));
        }
    };
  
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{isLogin ? 'Login' : 'Register'}</h2>
                    <button className="close" onClick={onClose}>&#10005;</button>
                    {errors?.passwords && <div className="error">{errors.passwords}</div>}
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="modal-body">
                            <input
                                type="text"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleChangeMobile}
                                placeholder="Enter Mobile Number"
                            />
                            {errors.mobileNumber && <div className="error">{errors.mobileNumber}</div>}
                            {!isLogin && (
                                <>
                                    <input
                                        type="text"
                                        name="fullName"
                                        placeholder="Enter Your Name"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                    />
                                    {errors.fullName && <div className="error">{errors.fullName}</div>}
                                </>
                            )}
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            {errors.password && <div className="error">{errors.password}</div>}
                            {!isLogin && (
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                />
                            )}
                            
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-full" type="submit">{isLogin ? 'Login' : 'Register'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginModal;
