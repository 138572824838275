import React from 'react';
import AppHeading from '../../components/heading/app.rest.heading';
import './app.services.scss';

function AppServices(props) {
    return (
      <React.Fragment>
        <AppHeading heading="OUR EVENTS"/>
        <section id="rest-services">
          <article>
          <div className="row services">
                {props.ServicesList && props.ServicesList.map((ServicesList,index) => (
                  <div className="boxes" key={index}>
                    <img src={ServicesList.img} alt=""/>
                    <div className="inner">
                      <div className="services_content">
                        <h3 className="title">​{ServicesList.heading}</h3>
                        <p className="desc">​{ServicesList.desc}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </article>
        </section>
      </React.Fragment>
    )
}

export default AppServices;
