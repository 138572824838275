import React,{lazy} from 'react'
import AppAboutus from '../pages/aboutus/app.aboutus';
import AppServices from '../pages/services/app.services';
import AppCareer from '../pages/career/app.career';
import AppContactus from '../pages/contactus/app.contactus';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
const AppLanding = lazy(() => import('../home/app.landing'));


function MyRoutes({data}) {
    return (
        <div className="ui-content">
          <Switch>
            <Route path="/about">
              <AppAboutus AboutUs={data.AboutUs} />
            </Route>
            <Route path="/services">
              <AppServices ServicesList={data.ServicesList} />
            </Route>
            <Route path="/blogs">
              <AppCareer />
            </Route>
            <Route path="/contact">
              <AppContactus ContactUs={data.ContactUs} />
            </Route>
            <Route path="/">
              <AppLanding data={data} />
            </Route>
          </Switch>
        </div>
    )
}

export default MyRoutes
