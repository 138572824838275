import axios from 'axios';

// const API_URL = 'https://nodejs-2pgi.onrender.com/api/auth'; // Change this to your API endpoint
const API_URL = 'http://localhost:8080/api/auth'; // Change this to your API endpoint

export const register = async (userData) => {
    const response = await axios.post(`${API_URL}/signup`, userData);
    return response.data;
};

export const login = async (userData) => {
    const response = await axios.post(`${API_URL}/signin`, userData);
    return response.data;
};

export const getAllUser = async (accessToken) => {
    const response = await axios({
        method: "GET",
        url: `${API_URL}/users`,
        headers: {'Authorization': `bearer ${accessToken}`}
    });
    return response.data;
};

export const removeUser = async (id,accessToken) => {
    const response = await axios({
        method: "DELETE",
        url: `${API_URL}/users/${id}`,
        headers: {'Authorization': `bearer ${accessToken}`}
    });
    return response.data;
};