import React, { useState, useEffect } from 'react';
import './LoginModal.css';

const ConfirmModal = ({ isOpen, onClose, onChildClick, isLogin, data, handleConfirmClick }) => {
    
  
    if (!isOpen && data) return null;
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div>{data}</div>
                <div className="modal-footer">
                    <button className="btn-sm btn btn-stroked" onClick={() => handleConfirmClick(false)}>No</button>
                    <button className="btn-sm btn btn" onClick={() => handleConfirmClick(true)}>Yes</button>
                </div>
            </div>
        </div>
        
    );
};

export default ConfirmModal;
