import React, { useEffect, useRef, Suspense } from 'react';
import axios from 'axios';
import AppHeader from './components/header/app.header';
import AppFooter from './components/footer/app.footer';
import './App.scss';
// import appConfig from './config/config';
import Data from './constant/data.json';
import MyRoutes from './route/MyRoutes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const App = () => {
  const nav = useRef(null);

  const handleScroll = () => {
    const lastScrollY = window.scrollY;
    if (nav.current) {
      nav.current.className = lastScrollY > 100 ? 'sticky' : '';
    }
    const journey = document.getElementById('journey');
    if (journey) {
      let windowHeight = window.innerHeight;
      let inView = lastScrollY - journey.offsetTop + windowHeight - 150;
      journey.className = inView > 0 ? 'inView' : '';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    // You can fetch users or other data here
    // fetchUsers();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <Suspense fallback={<div className="loading">Loading...</div>}>
          <AppHeader ref={nav} />
          <MyRoutes data={Data}/>
          <AppFooter />
      </Suspense>
    </Router>
  );
};

export default App;
